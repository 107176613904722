exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-blog-jsx": () => import("./../../../src/templates/blog/blog.jsx" /* webpackChunkName: "component---src-templates-blog-blog-jsx" */),
  "component---src-templates-pages-pages-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-pages-10-kontakt-index-mdx": () => import("./../../../src/templates/pages/pages.jsx?__contentFilePath=/Users/cawi/Sites/projects/werk12/content/pages/10--kontakt/index.mdx" /* webpackChunkName: "component---src-templates-pages-pages-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-pages-10-kontakt-index-mdx" */),
  "component---src-templates-pages-pages-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-pages-8-links-index-mdx": () => import("./../../../src/templates/pages/pages.jsx?__contentFilePath=/Users/cawi/Sites/projects/werk12/content/pages/8--links/index.mdx" /* webpackChunkName: "component---src-templates-pages-pages-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-pages-8-links-index-mdx" */),
  "component---src-templates-pages-pages-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-pages-9-datenschutzhinweise-index-mdx": () => import("./../../../src/templates/pages/pages.jsx?__contentFilePath=/Users/cawi/Sites/projects/werk12/content/pages/9--datenschutzhinweise/index.mdx" /* webpackChunkName: "component---src-templates-pages-pages-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-pages-9-datenschutzhinweise-index-mdx" */),
  "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2021-03-01-ohne-worte-index-mdx": () => import("./../../../src/templates/post/post.jsx?__contentFilePath=/Users/cawi/Sites/projects/werk12/content/posts/2021-03-01--ohne-worte/index.mdx" /* webpackChunkName: "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2021-03-01-ohne-worte-index-mdx" */),
  "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2021-04-04-umweltschutz-im-worldwideweb-index-mdx": () => import("./../../../src/templates/post/post.jsx?__contentFilePath=/Users/cawi/Sites/projects/werk12/content/posts/2021-04-04--umweltschutz-im-worldwideweb/index.mdx" /* webpackChunkName: "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2021-04-04-umweltschutz-im-worldwideweb-index-mdx" */),
  "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2023-01-25-usefull-links-for-starting-with-gatsby-index-mdx": () => import("./../../../src/templates/post/post.jsx?__contentFilePath=/Users/cawi/Sites/projects/werk12/content/posts/2023-01-25--usefull-links-for-starting-with-gatsby/index.mdx" /* webpackChunkName: "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2023-01-25-usefull-links-for-starting-with-gatsby-index-mdx" */),
  "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2023-02-24-conditional-content-wrapper-in-gatsby-index-mdx": () => import("./../../../src/templates/post/post.jsx?__contentFilePath=/Users/cawi/Sites/projects/werk12/content/posts/2023-02-24--conditional-content-wrapper-in-gatsby/index.mdx" /* webpackChunkName: "component---src-templates-post-post-jsx-content-file-path-users-cawi-sites-projects-werk-12-content-posts-2023-02-24-conditional-content-wrapper-in-gatsby-index-mdx" */),
  "component---src-templates-tag-tag-jsx": () => import("./../../../src/templates/tag/tag.jsx" /* webpackChunkName: "component---src-templates-tag-tag-jsx" */)
}

